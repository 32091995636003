@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  font-family: Arial, Helvetica, sans-serif;
}

/* Post */

img {
  border-radius: 0.5rem;
}

/* HomeScreen */

/* .card {
  border-radius: 1rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  margin: 3rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 2rem;
  max-width: 38vw;
  min-width: 18rem;
} */

.cardFlex {
  border-radius: 1rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 2rem;
  width: 600px;
  height: 100%;
  margin: 1rem;
  background-color: white;
}

.cardFlexSm {
  border-radius: 1rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 3rem;
  height: 100%;
  background-color: white;
}

.cardProfessional {
  border-radius: 1rem;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 2rem;
  width: 450px;
  height: 100%;
  margin-top: 5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background-color: white;
}

.card {
  border-radius: 1rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 2rem;
  margin: 1rem;
  background-color: white;
}

.card-profile-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  /* border-radius: 0.5rem; */
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 2rem;
  background-color: white;
}

.card-profile-bot {
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 2rem;
  margin: 1rem;
  background-color: white;
}

.card-profile-bot-mobile {
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  padding: 2rem;
  margin: 1rem;
  background-color: white;
}

.card-comment {
  border-radius: 1rem;
  border-color: transparent;
  background-color: #d9d9d9;
  border: 1px solid grey;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-top: 1%;
}

.card-icons {
  border-radius: 1rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  flex-wrap: nowrap;
  padding: 1rem;
  background-color: white;
}

.card-icons-map {
  border-radius: 1rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  flex-wrap: nowrap;
  padding-left: 0.2rem;
  padding-top: 0.5rem;
  padding-right: 0.2rem;
  padding-bottom: 0.5rem;
  background-color: white;
}

.card-detail {
  border-radius: 1rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
}

.card-detail-map {
  border-radius: 1rem;
  border-color: transparent;
  /* border: 0.1px solid gainsboro; */
  flex-wrap: nowrap;
  flex-direction: column;
  padding-left: 1rem;
  /* padding-top: 0.5rem; */
  padding-right: 1rem;
  /* padding-bottom: 0.5rem; */
  background-color: white;
}

.card-detail-map-mobile {
  border-radius: 1rem;
  border-color: transparent;
  /* border: 0.1px solid gainsboro; */
  flex-wrap: nowrap;
  flex-direction: column;
  padding-left: 0.2rem;
  /* padding-top: 0.5rem; */
  padding-right: 0.2rem;
  /* padding-bottom: 0.5rem; */
  background-color: white;
}

.card-post {
  border-radius: 1rem;
  border-radius: 1 rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 2rem;
  width: 480px;
  height: auto;
  margin-top: 1rem;
  background-color: white;
}

.card-new-post {
  border-radius: 1rem;
  border-radius: 1 rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 1rem;
  width: 480px;
  height: auto;
  margin-top: 1rem;
  background-color: white;
}


.card-input-post {
  border-radius: 1rem;
  border-radius: 1 rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 0.5rem;
  /* width: 180px; */
  height: auto;
  background-color: white;
}


/* Apply changes when the screen width is less than 1024px */
@media (max-width: 450px) {
  /* Modal */
  .modal-notification-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: white;
    border-radius: 0.5rem;
    outline: none;
    padding: 16px;
  };
}

@media (max-width: 550px) {
  .card-post {
    width: 90vw;
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .card-new-post {
    width: 90vw;
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 450px) {
  /* Modal */
  .modal-notification-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    border-radius: 0.5rem;
    outline: none;
    padding: 32px;
  };
}

.cardSmall {
  border-radius: 1rem;
  border-color: transparent;
  border: 0.1px solid gainsboro;
  box-shadow: 1px 2px 1px gainsboro;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 2rem;
  width: 75vw;
  margin: 1rem;
  background-color: white;
}

#wrapper {
  margin: 0 auto;
  display: block;
}

#pagination {
  margin: 0;
  padding: 0;
  text-align: center;
}

.waButton {
  padding-top: 1rem;
  width: 2.2rem;
}

.igButton {
  padding-top: 1rem;
  width: 1.7rem;
}

.lineButton {
  width: 2.2rem;
}

.plusButton {
  width: 1.5rem;
  margin-top: 0.7rem;
  margin-right: 1.5%;
}

.basic-single {
  width: 100%;
}

.highz {
  z-index: 999;
}

.midz {
  z-index: 111;
}

.google_btn {
  width: 230px;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 20px 0;
  color: #2c444e;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.google_btn>img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.google_btn>span {
  margin-left: 10px;
}

.verification-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-input {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.otp-input input[type='text'] {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 24px;
  margin-right: 10px;
}

.otp-input input[type='text']:last-child {
  margin-right: 0;
}

.otp-submit {
  background-color: #1e90ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.otp-submit:hover {
  background-color: #007fff;
}

.verification-submit:hover {
  background-color: #007fff;
}

.divider {
  height: 1.8rem;
  border-left: 1px solid grey;
  margin-left: 1rem;
  margin-right: 1rem;
}

.followButton.false {
  font-size: 0.8rem;
  background-color: #61876e;
  color: white;
  box-shadow: 1px 2px 1px black;
  border-radius: 8px;
}

.followButton:hover {
  background-color: #3c6255;
  scale: 1.05;
  box-shadow: 1px 2px 1px black;
}

.followButton:active {
  background-color: #3c6255;
  box-shadow: 1px 2px 1px black;
  transform: translateY(4px);
}

.followButton.true {
  font-size: 0.8rem;
  background-color: white;
  color: grey;
  box-shadow: 1px 2px 1px black;
  border-radius: 8px;
}

.dropdown-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.conversation-item {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.table-row {
  display: flex;
  border-bottom: 1px solid rgba(128, 128, 128, 0.1);
  border-top: 1px solid rgba(128, 128, 128, 0.1);
}

.table-col {
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  color: black;
}

.col-20 {
  width: 10%;
}

.col-75 {
  width: 75%;
}

.col-5 {
  width: 5%;
}

.divider-dropdown {
  width: 1px;
  height: 100%;
  background-color: #ccc;
  margin: 0 10px;
}

.direct-chat-icon {
  cursor: pointer;
}

.chat-logo {
  cursor: pointer;
}

.self-start {
  /* Styles for chat bubble aligned to the left */
  /* Example styles: */
  align-self: flex-start;
}

.self-end {
  /* Styles for chat bubble aligned to the right */
  /* Example styles: */
  background-color: #e5e5ea;
  color: black;
  align-self: flex-end;
}

@media (max-width:1024px){

  .leaflet-popup.leaflet-zoom-animated {
    width: 300px !important;
    
  }
  .leaflet-popup-content-wrapper {
    width: 300px !important;
  }
  .leaflet-popup-content{
    width: 295px !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
    /* margin-top: 2px !important; */
    /* margin-bottom: 2px !important; */
  }

}

@media (min-width:1024px){

  .leaflet-popup.leaflet-zoom-animated {
    width: 500px !important;
  }
  .leaflet-popup-content-wrapper {
    width: 500px !important;
  }
  .leaflet-popup-content{
    width: 450px !important;
  }

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4f4f4f;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #4f4f4f;
  }
}






.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 8px;
}

.user-table th,
td {
  border: 1px solid #ddd;
  padding: 10px 15px;
  text-align: center;
}

.user-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.user-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.user-table button {
  padding: 6px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.user-table button:hover {
  background-color: #0056b3;
}

.user-table button:disabled {
  background-color: gray;
  color: #fff;
  opacity: 0.5;
  cursor: not-allowed;
}

.admin-page-title {
  font-weight: bold;
  color: black;
  font-size: x-large;
}

.admin-table-container {
  max-width: 100%;
  overflow-x: auto;
}

.admin-page-button {
  background-color: rgb(249, 92, 61);
  color: white;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 2rem;
}

.verification-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  /* Adjusted to account for sticky header */
  margin-top: 100px;
}

.verification-container {
  border: 1px solid #ddd;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 2rem;
}

.verification-title {
  color: rgb(249, 92, 61);
  text-align: center;
  margin-top: 0;
  font-size: 24px;
}

.selected-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.selected-image-container img {
  max-width: 100%;
  margin-top: 10px;
}

.remove-image-button,
.submit-button {
  background-color: rgb(249, 92, 61);
  color: white;
  border: none;
  padding: 5px 20px;
  margin-top: 1%;
  cursor: pointer;
  border-radius: 2rem;
}

.custom-file-upload {
  border: 2px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 5px;
}

.custom-file-upload input[type='file'] {
  display: none;
}

.app-bar-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.4s;
}

.ribbon {
  width: 70px;
  height: 80px;
  background-color: #F95C3D;
  position: absolute;
  right: 80px;
  top: -5px;
  z-index: 5;

  &:before {
    content: '';
    position: absolute;
    z-index: 5;
    left: 0;
    bottom: -20px;
    border-left: 35px solid #F95C3D;
    border-right: 35px solid #F95C3D;
    border-bottom: 20px solid transparent;

  }

}

.ribbon:before,
.ribbon:after {
  position: absolute;
  content: "";
}

/* Map */
.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl .mapboxgl-ctrl-logo {
  display: none !important;
}

/* Custom Pagination Styles */
.swiper-pagination-bullet {
  background-color: red !important; /* Change bullet color */
}

.swiper-pagination-bullet-active {
  background-color: #F95C3D !important; /* Change active bullet color */
}

.inner {
  margin:0px -300px 0px 7.5px;
  padding-right:280px; /* Samakan dengan besar margin negatif */
}

.infinite-scroll-component__outerdiv {
  height: 100%;
}